$base-color: #294F58;
$light-teal: #00C5A3;
$medium-teal: #48B68D;
$dark-teal: #2F8473;
$lighter-gray: #95B0B7;
$light-gray: #627B82;
$medium-gray: #354B45;
$dark-gray: #2A362E;
$dark-red: #812B00;
$medium-red: #C2573F;
$light-red: #FF887F;
$off-white: #FFFAFA;


*{
    margin: 0;
    padding: 0;
    font-family: "Ubuntu", sans-serif;
}

// utility classes
.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-col {
    display: flex;
    flex-direction: column;
}
.justify-center {
    justify-content: center;
}
.align-center {
    align-items: center;
}
.space-between {
    justify-content: space-between;
}

// global styles
.App-header {
    display: flex;
    flex-direction: row;
    background-color: $base-color;
    .App-title {
        font-size: 1.5em;
        font-weight: bold;
        text-decoration: none;
    }
}

.App-body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.invalid-input-message {
    color: $dark-red;
    padding: 4px 0 0 0;
}

// Media Breakpoints

// small phones
@media only screen and (max-width: 600px) {
    .landing-page {
        background-color: $base-color;
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .signin-page {
        background-color: $off-white;
        height: 100vh;
        .App-header {
            display: none;
        }
    }

    .id-field {
        margin: 0.5em 0 0.1em 0;
        padding: 0.5em;
        background-color: $off-white;
        outline: none;
        border: none;
        border-radius: 0.75em;
        box-shadow: 0 0 0 2px $dark-gray;
        font-size: medium;
        &:focus {
            border: none;
            outline: none;
            box-shadow: 0 0 0 2px $medium-teal;
        }
    }

    .signin-frame {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-family: "Work Sans";
        height: 100vh;
        color: $dark-gray;
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        
        label {
            display: flex;
            flex-direction: column;
            font-family: "Work Sans";
            align-items: center;
            padding: 0.5em;
        }

        button {
            align-self: center;
            padding: 0.5em 0 0.5em 0;
            width: 50%;
            margin: 0.75em 0 0 0;
            border: solid 2px $medium-gray;
            background-color: $off-white;
            border-radius: 0.75em;
            font-family: "Work Sans";
            font-weight: bold;
            font-size: medium;
            &:active {
                background-color: $medium-gray;
                color: $off-white;
            }
        }

        .signin-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 25%;
        }
        .form-label {
            font-family: "Work Sans";
            font-size: large;
            font-weight: bold;
        }
        .invalid-span {
            font-size: x-small;
            padding: 0.1em 0 1em 0.5em;
            width: 85%
        }

        .id-link-group {
            display: flex;
            flex-direction: column;
            width: 60%;
            align-items: center;
            padding: 0 0 4% 0;
            .identity-link {
                padding: 0 2px 2px 2px;
            }
        }

        .identity-form-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: bold;
            font-size: x-large;
            font-family: "Work Sans";
            user-select: none;
        }

        .identity-brand-frame {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 3em;
            font-weight: bold;
            font-family: "Work Sans";
            color: $medium-teal;
            text-decoration: none;
            margin: 0 0 10% 0;
        }
        .identity-logo-frame {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: xx-large;
            font-weight: bold;
            font-family: "Work Sans";
            color: $dark-gray;
            text-decoration: none;
            .identity-page-logo {
                height: 60%;
                width: 80%;
                fill: $medium-teal;
                padding-left: 20%;
            }
        }
    }
    
    .verify-account-frame {
        justify-content: flex-start;
        .identity-logo-frame {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 2.5em;
            font-weight: bold;
            font-family: "Work Sans";
            color: $dark-gray;
            text-decoration: none;
            .identity-page-logo {
                height: 50%;
                width: 100%;
                fill: $medium-teal;
                padding-left: 20%;
            }
        }
    }

    .App-header {
        border-bottom: 1px solid $light-teal;
        justify-content: space-between;
        
        .identity-button-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            .identity-button {
                padding: 2px 4px 2px 4px;
                color: $off-white;
                text-decoration: none;
            }
        }

    }

    .App-title {
        color: $light-teal;
        margin: 4px 0px 4px 4px;
    }
}

// large phones and small portrait tablets
@media only screen and (min-width: 600px) {
}

// landscape tablets
@media only screen and (min-width: 768px) {
}

// laptops/desktops
@media only screen and (min-width: 992px) {

}
    