* {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.App-header {
  display: flex;
  flex-direction: row;
  background-color: #294F58;
}
.App-header .App-title {
  font-size: 1.5em;
  font-weight: bold;
  text-decoration: none;
}

.App-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.invalid-input-message {
  color: #812B00;
  padding: 4px 0 0 0;
}

@media only screen and (max-width: 600px) {
  .landing-page {
    background-color: #294F58;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .signin-page {
    background-color: #FFFAFA;
    height: 100vh;
  }
  .signin-page .App-header {
    display: none;
  }
  .id-field {
    margin: 0.5em 0 0.1em 0;
    padding: 0.5em;
    background-color: #FFFAFA;
    outline: none;
    border: none;
    border-radius: 0.75em;
    box-shadow: 0 0 0 2px #2A362E;
    font-size: medium;
  }
  .id-field:focus {
    border: none;
    outline: none;
    box-shadow: 0 0 0 2px #48B68D;
  }
  .signin-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: "Work Sans";
    height: 100vh;
    color: #2A362E;
  }
  .signin-frame form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .signin-frame label {
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    align-items: center;
    padding: 0.5em;
  }
  .signin-frame button {
    align-self: center;
    padding: 0.5em 0 0.5em 0;
    width: 50%;
    margin: 0.75em 0 0 0;
    border: solid 2px #354B45;
    background-color: #FFFAFA;
    border-radius: 0.75em;
    font-family: "Work Sans";
    font-weight: bold;
    font-size: medium;
  }
  .signin-frame button:active {
    background-color: #354B45;
    color: #FFFAFA;
  }
  .signin-frame .signin-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25%;
  }
  .signin-frame .form-label {
    font-family: "Work Sans";
    font-size: large;
    font-weight: bold;
  }
  .signin-frame .invalid-span {
    font-size: x-small;
    padding: 0.1em 0 1em 0.5em;
    width: 85%;
  }
  .signin-frame .id-link-group {
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: center;
    padding: 0 0 4% 0;
  }
  .signin-frame .id-link-group .identity-link {
    padding: 0 2px 2px 2px;
  }
  .signin-frame .identity-form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: x-large;
    font-family: "Work Sans";
    user-select: none;
  }
  .signin-frame .identity-brand-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3em;
    font-weight: bold;
    font-family: "Work Sans";
    color: #48B68D;
    text-decoration: none;
    margin: 0 0 10% 0;
  }
  .signin-frame .identity-logo-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: xx-large;
    font-weight: bold;
    font-family: "Work Sans";
    color: #2A362E;
    text-decoration: none;
  }
  .signin-frame .identity-logo-frame .identity-page-logo {
    height: 60%;
    width: 80%;
    fill: #48B68D;
    padding-left: 20%;
  }
  .verify-account-frame {
    justify-content: flex-start;
  }
  .verify-account-frame .identity-logo-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2.5em;
    font-weight: bold;
    font-family: "Work Sans";
    color: #2A362E;
    text-decoration: none;
  }
  .verify-account-frame .identity-logo-frame .identity-page-logo {
    height: 50%;
    width: 100%;
    fill: #48B68D;
    padding-left: 20%;
  }
  .App-header {
    border-bottom: 1px solid #00C5A3;
    justify-content: space-between;
  }
  .App-header .identity-button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .App-header .identity-button-group .identity-button {
    padding: 2px 4px 2px 4px;
    color: #FFFAFA;
    text-decoration: none;
  }
  .App-title {
    color: #00C5A3;
    margin: 4px 0px 4px 4px;
  }
}

